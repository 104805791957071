import React from 'react';
import { Form, Input, Button, Card, Row, Col } from 'antd';
import { useDispatch } from 'react-redux';
import { setDeliveryAddress, nextStep, prevStep } from '../../services/store/slices/cartSlice';

const ShippingAddress = ({ t }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const handleFinish = (values) => {
    dispatch(setDeliveryAddress(values));
    dispatch(nextStep());
  };

  return (
    <Card title={t('messages.shippingAddress')} className="shadow-md">
      <Form layout="vertical" form={form} onFinish={handleFinish}>
        <Form.Item
          name="delivery_address"
          required={false}
          label={t('messages.address')}
          rules={[
            {
              required: true,
              message: t('addressValidationRequired'),
            },
          ]}
        >
          <Input.TextArea rows={4} placeholder={t('address')} />
        </Form.Item>
        <Row justify='space-between' className="mt-6">
          <Col>
            <Button onClick={() => dispatch(prevStep())}>
              {t('messages.previous')}
            </Button>
          </Col>
          <Col>
            <Button type="primary" htmlType="submit">
              {t('messages.continue')}
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default ShippingAddress;

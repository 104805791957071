import { axiosInstance } from "../services/axiosInstance";
import catchAsync from "../utils/catchAsync";
import i18next from "i18next";

export const listProducts = catchAsync(async (filter = {}) => {
  const params = {
    ...filter,
    ...(filter.name ? { 'filter[name]': filter.name } : {}),
    ...(filter.sort ? { sort: filter.sort } : {}),
  };

  const { data } = await axiosInstance.get('products', {
    params,
    headers: {
      "Accept-Language": i18next.language,
    },
  });
  return data;
});

export const listPopularProducts = catchAsync(async () => {
  const { data } = await axiosInstance.get('popular/products', {
    headers: {
      "Accept-Language": i18next.language,
    }
  });
  return data;
});

export const listReviews = async (code) => {
  const { data } = await axiosInstance.get(`/products/${code}/reviews`, {
    headers: {
      "Accept-Language": i18next.language,
    }
  });
  return data;
};

export const storeReviews = async (productId, reviewData) => {
  const { data } = await axiosInstance.post(`/products/${productId}/reviews`, reviewData, {
    headers: {
      "Accept-Language": i18next.language,
    },
  });
  return data;
};

export const listCategoryProducts = async (code) => {
  const { data } = await axiosInstance.get(`category/${code}/products`, {
    headers: {
      "Accept-Language": i18next.language,
    }
  });
  return data;
};

export const productDetails = async (code) => {
  const { data } = await axiosInstance.get(`products/${code}`, {
    headers: {
      "Accept-Language": i18next.language,
    }
  });
  return data;
};


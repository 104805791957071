import React from 'react';
import { Card, Button, Result } from 'antd';
import { useNavigate } from "react-router-dom";

const Payment = ({ t }) => {
  const navigate = useNavigate();

  return (
    <Card className="max-w-2xl mx-auto shadow-sm">
      <Result
        status="success"
        title={t("messages.orderPlacedSuccessfully")}
        subTitle={`Order number: ${Math.random().toString(36).substr(2, 9).toUpperCase()}`}
        extra={[
          <Button className='mb-2' type="primary" onClick={() => navigate("/orders")} key="console" size="large">
            {t("trackOrder")}
          </Button>,
          <Button key="buy" onClick={() => navigate("/")} size="large">
            {t("continueShopping")}
          </Button>
        ]}
      />
    </Card >
  );
};

export default Payment;

import React from "react";
import { withTranslation } from "react-i18next";
import { Button, Form } from "antd";
import { GiftOutlined } from "@ant-design/icons";
import { t } from "i18next";
import { contactUs } from "../../../network/contact";
import { useMutation } from "@tanstack/react-query";
import cogoToast from "cogo-toast";
import { useNavigate } from "react-router";

const Promo = ({ user }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { mutate: onContact, isPending } = useMutation({
    mutationFn: (formData) => contactUs(formData),
    onSuccess: (data) => {
      cogoToast.success(t("messages.messageSentSuccessfully"), { position: "top-right" });
    },
    onError: () => {
      cogoToast.warn(t("messages.warningOccurred"), { position: "top-right" });
    },
  });

  const onFinish = (values) => {
    if (!localStorage.getItem('token')) {
      navigate("/login");
      return;
    }

    const enhancedValues = {
      ...values,
      form_name: "promo_code",
      name: user?.name,
      email: user?.email,
    };
    onContact(enhancedValues);
  };

  return (
    <div className="mb-10 bg-gradient-to-b from-gray-50 to-white relative overflow-hidden">
      <div className="container">
        <section className="relative w-full  overflow-hidden rounded">
          <div className="relative mx-auto px-4 sm:px-6 lg:px-8 py-16 text-center">
            <h2 className="mt-8 text-4xl font-bold text-gray-900 sm:text-5xl">
              {t('home.exclusiveDiscounts')}
            </h2>

            <p className="mt-6 max-w-2xl mx-auto text-xl text-gray-500">
              {t('home.exclusiveDiscountsDescription')}
            </p>

            <Form
              form={form}
              name="contact"
              onFinish={onFinish}
              layout="vertical"
            >
              <Button
                type="primary"
                size="large"
                icon={<GiftOutlined />}
                htmlType="submit"
                loading={isPending}
                className="mt-8 px-8"
              >
                {t('home.exclusiveDiscountsButton')}
              </Button>
            </Form>
          </div>
        </section>
      </div>
    </div>
  );
};

export default withTranslation()(Promo);

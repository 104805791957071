// rootReducer.js

import { combineReducers } from "@reduxjs/toolkit";
import cartReducer from './slices/cartSlice';
import userReducer from "./slices/user";

/**
 * @typedef {Object} RootState - The root state object.
 * @property {ServiceState} service - The category slice state.
 */

export default combineReducers({
  cart: cartReducer,
  user: userReducer,
});

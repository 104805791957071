import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './App.css';
import AppRoute from './routes/AppRoute';
import QueryProvider from './services/react-query';

const App = () => {
  const [className, setClassName] = useState('ltr');
  const { i18n } = useTranslation();

  useEffect(() => {
    if (i18n.language === 'ar') {
      setClassName('rtl');
    } else {
      setClassName('ltr');
    }
  }, [i18n.language]);

  return (
    <QueryProvider>
      <BrowserRouter>
        <div className={className}>
          <AppRoute />
        </div>
      </BrowserRouter>
    </QueryProvider>
  );
};

export default App;

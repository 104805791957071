export const queryKeys = {
  listOffers: "listOffers",
  listProducts: "listProducts",
  listFavorites: "listFavorites",
  listPopularProducts: "listPopularProducts",
  listOrders: "listOrders",
  reviews: "reviews",
  listCart: "listCart",
  auth: "auth",
  favorite: "favorite",
  userData: "userData",
};

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: [],
  promoCodeId: null,
  promoDiscount: 0,
  currentStep: 0,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCartItems(state, action) {
      state.items = action.payload;
    },
    setCart(state, action) {
      state.data = action.payload;
    },
    setPromoCodeId(state, action) {
      state.promoCodeId = action.payload;
    },
    applyPromo(state, action) {
      state.promoCode = action.payload.code;
      state.promoDiscount = action.payload.discount;
    },
    setDeliveryAddress(state, action) {
      state.delivery_address = action.payload.delivery_address;
    },
    updateItemQuantity(state, action) {
      const { itemId, quantity } = action.payload;
      const item = state.items.find((item) => item.id === itemId);
      if (item) {
        item.quantity = quantity;
      }
    },
    removeItem(state, action) {
      state.items = state.items.filter((item) => item.id !== action.payload);
    },
    nextStep: (state) => {
      state.currentStep += 1;
    },
    prevStep: (state) => {
      if (state.currentStep > 0) {
        state.currentStep -= 1;
      }
    },
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },
    resetSteps: (state) => {
      state.currentStep = 0;
    },
  },
});

export const { setCartItems, setPromoCodeId, setCart, applyPromo, setDeliveryAddress, updateItemQuantity, nextStep, prevStep, resetSteps, removeItem, setCurrentStep } = cartSlice.actions;

export default cartSlice.reducer;

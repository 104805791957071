import React, { useEffect } from 'react';
import { Steps } from 'antd';
import CartReview from './CartReview';
import ShippingAddress from './ShippingAddress';
import Payment from './Payment';
import Confirmation from './Confirmation';
import { withTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { resetSteps } from '../../services/store/slices/cartSlice';
import { queryKeys } from '../../services/react-query/queryKeys';
import { listCart } from '../../network/cart';
import { useQuery } from '@tanstack/react-query';
import { CheckCircle, CreditCard, ShoppingCart, Truck } from 'lucide-react';

const Cart = ({ t, i18n }) => {
  const currentStep = useSelector((state) => state.cart.currentStep);
  const dispatch = useDispatch();

  const { data: cartData } = useQuery({
    queryKey: [queryKeys.listCart, i18n],
    queryFn: listCart,
    enabled: !!i18n,
  });

  useEffect(() => {
    dispatch(resetSteps());
    localStorage.removeItem('currentStep');
  }, [dispatch]);

  const steps = [
    { title: t('messages.cartReview'), icon: <ShoppingCart />, content: <CartReview t={t} i18n={i18n} /> },
    { title: t('messages.address'), icon: <Truck />, content: <ShippingAddress t={t} /> },
    { title: t('messages.payment'), icon: <CreditCard />, content: <Payment t={t} /> },
    { title: t('messages.confirmation'), con: <CheckCircle />, content: <Confirmation t={t} /> },
  ];

  const validStep = steps[currentStep] ? steps[currentStep] : steps[0];

  return (
    <div className="p-10 bg-gray-100">
      <main className="container mx-auto px-4 py-8">
        {cartData?.data?.length > 0 && (
          <div className="bg-white p-6 rounded-lg shadow-md mb-8">
            <Steps current={currentStep}>
              {steps.map((step) => (
                <Steps.Step key={step.title} title={step.title} icon={step.icon} />
              ))}
            </Steps>
          </div>
        )}
        <div className="mt-8">{validStep.content}</div>
      </main>
    </div>

  );
};

export default withTranslation()(Cart);

import React, { useState } from "react";
import { Col, Row, Spin } from "antd";
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import cogoToast from "cogo-toast";
import { queryKeys } from "../../services/react-query/queryKeys";
import { listPopularProducts } from "../../network/product";
import { useMutation, useQuery } from "@tanstack/react-query";
import queryClient from "../../services/react-query/queryClient";
import { addCart } from "../../network/cart";
import { Card, Button, Flex, Typography, Rate } from 'antd';
import { ShoppingCartOutlined } from '@ant-design/icons';

const { Meta } = Card;

const AllPopularProduct = ({ user, t, i18n }) => {
  const [currentProductId, setCurrentProductId] = useState(null);

  const { data: productData, isLoading } = useQuery({
    queryKey: [queryKeys.listPopularProducts, i18n],
    queryFn: listPopularProducts,
    enabled: !!i18n,
  });

  const { mutate: onAddCart, isPending: isCartPending } = useMutation({
    mutationFn: (formData) => addCart(formData),
    onSuccess: (data) => {
      if (data.status === "true") {
        cogoToast.success(t("messages.itemAddedSuccessfully"), { position: "top-right" });
        queryClient.invalidateQueries([queryKeys.listCart]);
      } else {
        cogoToast.warn(t("messages.warningOccurred"), { position: "top-right" });
      }
      setCurrentProductId(null);
    },
    onError: () => {
      cogoToast.warn(t("messages.warningOccurred"), { position: "top-right" });
      setCurrentProductId(null);
    }
  });

  const addToCart = (productId) => {
    if (!localStorage.getItem("token")) {
      cogoToast.warn(t("messages.addItemsLoginRequired"), {
        position: "top-right",
      });
    } else {
      setCurrentProductId(productId);
      const formData = new FormData();
      formData.append("quantity", 1);
      formData.append("item_id", productId);
      formData.append("user_id", user.id);
      formData.append("is_product", 1);
      onAddCart(formData);
    }
  };

  if (isLoading) {
    return (
      <div className="grid content-center bg-gray-100 h-[380px]">
        <div className="text-center">
          <Spin size="large" />
        </div>
      </div>
    );
  }

  const productView = productData?.data.map((item, i) => (
    <Col xl={6} lg={6} md={8} sm={12} xs={24} key={i.toString()}>
      <Card
        hoverable
        className="mb-5 xl:w-[270px] 2xl:w-full"
        cover={
          <Link to={`/products/${item.id}/details`} className="custom-links" style={{ textDecoration: "none" }}>
            <img
              alt="example"
              src={item.images[0]}
              className="w-full"
            />
          </Link>
        }
      >
        <Meta title={item.name} description={
          <div>
            <Rate disabled defaultValue={item.average_rating} />

            <Flex justify="space-between" className="mt-3">
              <div>
                {item.new_price === null ? (
                  <Typography.Text className="text-[#0F63E3]">{item.price}{t('currency')}</Typography.Text>
                ) : (
                  <>
                    <Typography.Text className="text-[#0F63E3]">{item.new_price}{t('currency')}</Typography.Text>
                    <Typography.Text delete type="secondary" className="mx-2">{item.price}{t('currency')}</Typography.Text>
                  </>
                )}
              </div>

              <Button type="primary" onClick={() => addToCart(item.id)}
                  loading={isCartPending && currentProductId === item.id}
                  icon={<ShoppingCartOutlined />} size={"lg"} />
            </Flex>
          </div>
        } />
      </Card>
    </Col>
  ));

  return (
    <div className="bg-gray-100">
      <div className="container p-10">
        <Typography.Title
          level={4}
        >
          {t('home.popularProducts')}
        </Typography.Title>
        <Row gutter={10} className="mt-5">{productView}</Row>
      </div>
    </div>
  );
};

export default withTranslation()(AllPopularProduct);

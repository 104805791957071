import { axiosInstance } from "../services/axiosInstance";
import catchAsync from "../utils/catchAsync";
import i18next from "i18next";

export const validPromo = catchAsync(async (code) => {
  const { data } = await axiosInstance.get(`valid-promo-code?promo_code=${code}`, {
    headers: {
      "Accept-Language": i18next.language,
    }
  });
  return data;
});

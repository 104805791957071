import React, { useEffect } from "react";
import { UserIcon, Mail } from "lucide-react";
import {
  Button,
  Form,
  Input,
  Card,
  Typography
} from 'antd';
import { withTranslation } from 'react-i18next';
import { updateUser } from "../../network/auth";
import queryClient from "../../services/react-query/queryClient";
import { queryKeys } from "../../services/react-query/queryKeys";
import cogoToast from "cogo-toast";
import { useMutation } from "@tanstack/react-query";
const { Title, Text } = Typography;

const AccountSetting = ({ user, t }) => {
  const [form] = Form.useForm();

  const { mutate: updateUserInfo, isPending } = useMutation({
    mutationFn: (updatedData) => updateUser(user.id, updatedData),
    onSuccess: () => {
      cogoToast.success(t("messages.updateData"), { position: "top-right" });
      queryClient.invalidateQueries([queryKeys.userData]);
    },
    onError: () => {
      cogoToast.warn(t("messages.warningOccurred"), { position: "top-right" });
    }
  });

  useEffect(() => {
    form.setFieldsValue({
      name: user?.name,
      email: user?.email,
    });
  }, [user, form]);

  const onFinish = (values) => {
    updateUserInfo({ ...values });
  };

  return (
    <main className="bg-[#eff0f5] py-12 px-4 sm:px-6 lg:px-8">
      <Card
        className="max-w-2xl mx-auto shadow-sm hover:shadow-md transition-shadow duration-300"
        bordered={false}
      >
        <div className="space-y-6">
          <div>
            <Title level={2}>
              {t('profile')}
            </Title>
            <Text type="secondary" className="block mt-2">
              {t('manage')}
            </Text>
          </div>

          <Form
            form={form}
            layout="vertical"
            initialValues={{
              name: user?.name,
              email: user?.email,
            }}
            onFinish={onFinish}
            className="mt-8 space-y-6"
          >
            <Form.Item
              label={t('auth.name')}
              name="name"
              required={false}
              rules={[
                { required: true, message: t('auth.nameValidationRequired'), whitespace: true },
              ]}
            >
              <Input
                prefix={<UserIcon className="h-5 w-5 text-gray-400" />}
                className="rounded-md hover:border-blue-400 transition-colors"
                size="large"
              />
            </Form.Item>

            <Form.Item
              label={t('auth.email')}
              name="email"
              required={false}
              rules={[
                { type: 'email', message: t('auth.emailValidation') },
                { required: true, message: t('auth.emailValidationRequired') }
              ]}
            >
              <Input
                prefix={<Mail className="h-5 w-5 text-gray-400" />}
                className="rounded-md hover:border-blue-400 transition-colors"
                size="large"
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={isPending}
                size="large"
                className="w-full sm:w-auto hover:opacity-90 transition-opacity"
              >
                {t('infoUpdate')}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </main>
  );
};

export default withTranslation()(AccountSetting);
import React from "react";
import { Button } from "antd";
import { Share2 } from "lucide-react";

const ShareButton = () => {
  const handleShare = async () => {
    const shareData = {
      title: "Your Page Title",
      text: "Check out this awesome page!",
      url: window.location.href, // Current page URL
    };

    if (navigator.share) {
      try {
        await navigator.share(shareData);
      } catch (err) {
        console.log("Failed to copy link.")
      }
    } else {
      navigator.clipboard.writeText(shareData.url).then(
        console.log("Failed to copy link.")
      );
    }
  };

  return (
    <Button
      icon={<Share2 className="w-5 h-5" />}
      onClick={handleShare}
      size="large"
      className="mt-2"
      style={{ color: "#1677ff" }}
    />
  );
};

export default ShareButton;

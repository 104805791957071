import React, { useState } from "react";
import cogoToast from "cogo-toast";
import { Link, useNavigate } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../services/react-query/queryKeys";
import { addCart } from "../../network/cart";
import { addToFavorite, checkFavorite } from "../../network/favourite";
import { Card, Modal, Input, Row, Col, Rate, Button, Flex, Typography, Image, Tabs, Avatar, Form } from 'antd';
import { HeartFilled, MinusOutlined, PlusOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { rating } from "../../network/rate";
import { CreditCard, Truck } from "lucide-react";
import { listProducts, listReviews, storeReviews } from "../../network/product";
import Meta from "antd/es/card/Meta";
import ShareButton from "../ShareButton";

const ProductDetails = ({ data, user, t, i18n }) => {
  const [quantity, setQuantity] = useState("");
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const [form] = Form.useForm();
  const isRTL = i18n.language === 'ar';

  const onRating = useMutation({
    mutationFn: ({ rate, product_id }) => rating(rate, product_id),
    onSuccess: (data) => {
      if (data.status === "true") {
        cogoToast.success(t('messages.rateSuccess'), { position: "top-right" });
        queryClient.invalidateQueries([queryKeys.listProducts, data?.id, user?.id]);
        window.location.reload();
      } else {
        cogoToast.error(t("messages.errorOccurred"), { position: "top-right" });
      }
    },
    onError: () => {
      cogoToast.error(t("messages.errorOccurred"), { position: "top-right" });
    },
  });

  const addRating = (rate) => {
    if (!localStorage.getItem("token")) {
      cogoToast.warn(t("messages.addItemsLoginRequired"), { position: "top-right" });
    } else {
      let product_id = data?.id;
      onRating.mutate({ rate, product_id });
    }
  };

  const { mutate: onAddCart, isPending: isCartPending } = useMutation({
    mutationFn: (formData) => addCart(formData),
    onSuccess: (data) => {
      if (data.status === "true") {
        cogoToast.success(t("messages.itemAddedSuccessfully"), {
          position: "top-right"
        });
        queryClient.invalidateQueries([queryKeys.cartList]);
      } else {
        cogoToast.warn(t("messages.warningOccurred"), {
          position: "top-right",
        });
      }
    },
    onError: () => {
      cogoToast.warn(t("messages.warningOccurred"), {
        position: "top-right",
      });
    },
  });

  const addToCart = () => {
    if (quantity.length === 0 || quantity === "0") {
      cogoToast.error(t("messages.selectQuantity"), { position: "top-right" });
    } else if (!localStorage.getItem("token")) {
      cogoToast.warn(t("messages.addItemsLoginRequired"), { position: "top-right" });
    } else {
      const formData = new FormData();
      formData.append("quantity", quantity);
      formData.append("item_id", data.id);
      formData.append("user_id", user.id);
      formData.append("is_product", 1);
      onAddCart(formData);
    }
  };

  const { mutate: onBuyNow, isPending: isBuyPending } = useMutation({
    mutationFn: (formData) => addCart(formData),
    onSuccess: (data) => {
      if (data.status === "true") {
        cogoToast.success(t("messages.purchaseSuccessful"), {
          position: "top-right"
        });
        queryClient.invalidateQueries([queryKeys.cartList]);
        navigate("/cart");
      } else {
        cogoToast.warn(t("messages.errorOccurred"), {
          position: "top-right"
        });
      }
    },
    onError: () => {
      cogoToast.warn(t("messages.errorOccurred"), {
        position: "top-right"
      });
    },
  });

  const buyNowHandler = () => {
    if (quantity.length === 0 || quantity === "0") {
      cogoToast.error(t("messages.selectQuantity"), { position: "top-right" });
    } else if (!localStorage.getItem("token")) {
      cogoToast.warn(t("messages.addItemsLoginRequired"), { position: "top-right" });
    } else {
      const formData = new FormData();
      formData.append("quantity", quantity);
      formData.append("item_id", data?.id);
      formData.append("user_id", user?.id);
      formData.append("is_product", 1);
      onBuyNow(formData);
    }
  };

  const { mutate: onAddToFavorite, isPending: isFavoritePending } = useMutation({
    mutationFn: ({ product_id }) => addToFavorite(product_id),
    onSuccess: (data) => {
      if (data.isFavorite === true) {
        cogoToast.success(t("messages.addedToFavorites"), { position: "top-right" });
        queryClient.invalidateQueries([queryKeys.favorite, data?.id, user?.id]);
      } else {
        cogoToast.success(t("messages.removedFromFavorites"), { position: "top-right" });
        queryClient.invalidateQueries([queryKeys.favorite, data?.id, user?.id]);
      }
    },
    onError: () => {
      cogoToast.error(t("messages.errorOccurred"), { position: "top-right" });
    },
  });

  const { mutate: onAddReview, isPending: isReviewPending } = useMutation({
    mutationFn: async ({ productId, reviewData }) => await storeReviews(productId, reviewData),
    onSuccess: async (data) => {
      if (data.status === "true") {
        cogoToast.success(t("messages.orderPlacedSuccessfully"), {
          position: "top-right",
        });
        form.resetFields();
        setIsReviewModalOpen(false);
        queryClient.invalidateQueries([queryKeys.listReviews]);
      } else {
        cogoToast.error(t("messages.errorOccurred"), {
          position: "top-right",
        });
        form.resetFields();
        setIsReviewModalOpen(false);
      }
    },
    onError: () => {
      cogoToast.error(t("messages.errorOccurred"), {
        position: "top-right",
      });
      form.resetFields();
      setIsReviewModalOpen(false);
    },
  });

  const onFinish = async (values) => {
    const productId = data.id;
    const reviewData = values;
    onAddReview({ productId, reviewData });
  };

  const addToFavourite = () => {
    if (!localStorage.getItem("token")) {
      cogoToast.warn(t("messages.addItemsLoginRequired"), { position: "top-right" });
    } else {
      let product_id = data?.id;
      onAddToFavorite({ product_id });
    }
  };

  const { data: favoriteData } = useQuery({
    queryKey: [queryKeys.favorite, data?.id],
    queryFn: () => checkFavorite(data?.id),
    enabled: !!user?.id && !!data?.id,
  });

  const { data: reviewsData } = useQuery({
    queryKey: [queryKeys.reviews, data?.id],
    queryFn: () => listReviews(data?.id),
    enabled: !!data?.id,
  });

  const { data: productData } = useQuery({
    queryKey: [queryKeys.listProducts, i18n],
    queryFn: () => listProducts(),
    enabled: !!i18n,
  });

  const quantityOnChange = (event) => {
    setQuantity(event.target.value);
  };

  const addQuantity = () => {
    setQuantity((prevQuantity) => Number(prevQuantity) + 1);
  };

  const subtractQuantity = () => {
    setQuantity((prevQuantity) => (prevQuantity > 1 ? Number(prevQuantity) - 1 : prevQuantity));
  };

  const reviewHandler = () => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsReviewModalOpen(true);
    } else {
      navigate('/login');
    }
  };

  const hasUserReviewed = reviewsData?.data?.some(review => review.user_id === user?.id);

  const MyView = productData?.data.filter((item) => item.id !== data.id).slice(0, 4).map((item, i) => {
    return (
      <Col key={i} className={`my-6 flex justify-center ${isRTL ? 'rtl-card' : ''}`}>
        <Card
          hoverable
          className="mb-5 xl:w-[260px]"
          cover={
            <Link to={`/products/${item.id}/details`} className="custom-links" style={{ textDecoration: "none" }}>
              <img
                alt="example"
                src={item?.images[0]}
                className="w-full"
              />
            </Link>
          }
        >
          <Meta
            title={item.name}
            description={
              <div>
                <Rate disabled defaultValue={item.average_rating} />

                <Flex justify="space-between" className="mt-3">
                  <div>
                    {item.new_price === null ? (
                      <Typography.Text className="text-[#0F63E3]">{item.price}{t('currency')}</Typography.Text>
                    ) : (
                      <>
                        <Typography.Text className="text-[#0F63E3]">{item.new_price}{t('currency')}</Typography.Text>
                        <Typography.Text delete type="secondary" className="mx-2">{item.price}{t('currency')}</Typography.Text>
                      </>
                    )}
                  </div>
                </Flex>
              </div>
            }
          />
        </Card>
      </Col>
    );
  });

  return (
    <div className="bg-gray-100 p-10">
      <div className="container">
        <Card hoverable>
          <Row gutter={16}>
            <Col xl={8} lg={8} md={24} sm={24}>
              <div>
                <Image.PreviewGroup>
                  <Image
                    src={data?.images[0]}
                    alt="Smart Lock Product"
                    className="rounded-lg"
                  />
                  <div className="grid grid-cols-4 gap-4 mt-4">
                    {data?.images?.slice(1).map((image, index) => (
                      <Image
                        key={index}
                        src={image}
                        alt={`Smart Lock View ${index + 1}`}
                        className="w-full rounded-lg cursor-pointer hover:opacity-75"
                      />
                    ))}
                  </div>
                </Image.PreviewGroup>
              </div>
            </Col>
            <Col xl={16} lg={16} md={24} sm={24} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <Row justify="space-between" className="mb-0">
                <Col>
                  <div className="xl:text-[26px]">
                    {data?.name}
                  </div>
                </Col>
                <Col>
                  <HeartFilled
                    className={`${favoriteData?.isFavorite ? 'text-red-500' : 'text-gray-300'}`}
                    style={{ fontSize: '30px' }}
                    onClick={addToFavourite}
                    isLoading={isFavoritePending}
                  />
                </Col>
              </Row>

              <Flex className="text-gray-500">
                {data?.description}
              </Flex>

              <Flex>
                <Rate name="rate"
                  size={25}
                  defaultValue={data?.average_rating}
                  precision={0.5}
                  disabled
                  onChange={(newValue) => addRating(newValue)}
                />
                <span className="text-gray-500">({reviewsData?.data?.length ?? 0} {t('reviews')})</span>
              </Flex>

              <Row justify={"space-between"}>
                <Col className="xl:my-0 lg:my-0 md:my-0 my-2" xl={12} lg={12} md={12} sm={12} xs={24}>
                  {data?.new_price === null ?
                    <div className="flex items-baseline gap-4 mb-6">
                      <span className="xl:text-3xl md:text-2xl lg:text-2xl text-1xl font-bold text-blue-600">
                        {data?.price}{t('currency')}
                      </span>
                    </div>
                    :
                    <div className="flex items-baseline gap-4 mb-6">
                      <span className="xl:text-3xl md:text-2xl lg:text-2xl text-1xl font-bold text-blue-600">{data?.new_price}{t('currency')}</span>
                      <span className="text-gray-500 line-through">{data?.price}{t('currency')}</span>
                      <span className="bg-red-100 text-red-800 text-sm font-medium px-2.5 py-0.5 rounded">
                        {Math.round(((data?.price - data?.new_price) / data?.price) * 100)}% {t('discount')}
                      </span>
                    </div>
                  }
                </Col>
                <Col className="xl:my-0 lg:my-0 md:my-0" xl={7} lg={8} md={8} sm={12} xs={24} >
                  <Flex align="center" justify="center" className=" p-2" >
                    <Button onClick={subtractQuantity} shape="circle" icon={<MinusOutlined />} size={"lg"} />
                    <input
                      onChange={quantityOnChange}
                      type="number"
                      className="xl:w-[90px] text-xl w-[60px] border-none text-center rounded-none placeholder-gray-500 focus:outline-none focus:shadow-none focus:border-gray-300"
                      placeholder="1"
                      min="1"
                      value={quantity}
                    />
                    <Button shape="circle" icon={<PlusOutlined />} onClick={addQuantity} size={"lg"} />
                  </Flex>
                </Col>
              </Row>

              <Row gutter={9}>
                <Col xl={11} lg={11} md={11} sm={24} xs={24}>
                  <Button
                    type="primary"
                    icon={<ShoppingCartOutlined />}
                    className="w-full mt-2"
                    onClick={addToCart}
                    size="large"
                    loading={isCartPending}
                  >
                    {t('cart')}
                  </Button>
                </Col>

                <Col xl={11} lg={11} md={11} sm={24} xs={24}>
                  <Button
                    loading={isBuyPending}
                    icon={<CreditCard className="w-5 h-5" />}
                    onClick={buyNowHandler}
                    size="large"
                    style={{ background: "#dbeafe", color: "#1677ff" }}
                    className="hover:bg-blue-200 w-full mt-2">
                    {t('buy')}
                  </Button>
                </Col>
                <Col xl={2} lg={2} md={2} sm={24} xs={24}>
                  <ShareButton />
                </Col>
              </Row>

              <div className="flex items-center gap-3 p-4 bg-gray-50 rounded-lg">
                <Truck className="w-6 h-6 text-blue-600" />
                <div>
                  <p className="font-medium"> {t('freeDelivery')}</p>
                  <p className="text-sm text-gray-500">
                    {t('enterPostalCode')}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Card>

        <Card className="my-8">
          <Tabs defaultActiveKey="1" size="large" >
            <Tabs.TabPane
              tab={<div className={`${isRTL ? 'ml-6' : 'ml-0'}`}>{t('shipping')}</div>}
              key="1"
            >
              <div className="prose max-w-none">
                <p>
                  {data?.description}
                </p>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab={t('reviews')} key="2">
              <div className="mb-6">
                {!hasUserReviewed && (
                  <Button
                    type="primary"
                    size="large"
                    onClick={reviewHandler}
                    className="bg-blue-500"
                  >
                    {t('writeAReview')}
                  </Button>
                )}
              </div>
              <div className="space-y-6">
                {reviewsData?.data?.map((review, idx) => (
                  <div key={idx} className="border-b pb-6">
                    <div className="flex items-start gap-4">
                      <Avatar size={40}>{review.user.name}</Avatar>
                      <div className="flex-1">
                        <div className="flex items-center gap-2 mb-2">
                          <span className="font-semibold">{review.user.name}</span>
                          <span className="text-gray-500">•</span>
                          <span className="text-gray-500">{review.date}</span>
                        </div>
                        <Rate defaultValue={review.rate} disabled />
                        <h4 className="font-semibold mt-2">{review.title}</h4>
                        <p className="text-gray-600 mt-2">{review.comment}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab={t('details')} key="3">
              <div className="prose max-w-none">
                <h4>{t('shippingInformationTitle')}</h4>
                <ul>
                  <li>{t('expressShipping')}</li>
                  <li>{t('internationalShipping')}</li>
                  <li>{t('orderProcessing')}</li>
                </ul>
              </div>
            </Tabs.TabPane>
          </Tabs>
        </Card>

        {/* Related Products */}
        <Card>
          <h2 className="text-2xl font-bold mb-8">{t('relatedProducts')}</h2>
          <Row gutter={16}>
            {MyView}
          </Row>
        </Card>

        <Modal
          title="Write a Review"
          open={isReviewModalOpen}
          onCancel={() => setIsReviewModalOpen(false)}
          footer={null}
        >
          <Form form={form} layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item
              name="rate"
              rules={[
                {
                  required: true,
                  message: "Please rate the product",
                },
              ]}
            >
              <Rate />
            </Form.Item>
            <Form.Item
              name="title"
              rules={[
                {
                  required: true,
                  message: "Please enter a title",
                },
              ]}
            >
              <Input placeholder="Review Title" />
            </Form.Item>
            <Form.Item
              name="comment"
              rules={[
                {
                  required: true,
                  message: "Please enter your review",
                },
              ]}
            >
              <Input.TextArea rows={4} placeholder="Write your review here..." />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isReviewPending} className="bg-blue-500">
                {t('submitReview')}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default withTranslation()(ProductDetails);
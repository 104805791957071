import { Lock, Shield, Zap } from "lucide-react";
import React from "react";
import { Row, Col, Button } from "antd";
import { motion } from "framer-motion";
import { t } from "i18next";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <main className="min-h-screen bg-gradient-to-r from-blue-500 to-blue-400 relative overflow-hidden">
      <div className="absolute inset-0">
        {[...Array(20)].map((_, i) => (
          <motion.div
            key={i}
            className="absolute w-2 h-2 bg-white rounded-full opacity-20"
            initial={{
              x: Math.random() * window.innerWidth,
              y: -20,
            }}
            animate={{
              y: window.innerHeight + 20,
              x: Math.random() * window.innerWidth,
            }}
            transition={{
              duration: 5 + Math.random() * 5,
              repeat: Infinity,
              ease: "linear",
            }}
          />
        ))}
      </div>

      <Row className="min-h-screen items-center px-4 md:px-12 lg:px-24">
        <Col xs={24} md={12} className="z-10">
          <motion.div
            initial={{
              opacity: 0,
              y: 20,
            }}
            animate={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              duration: 0.8,
            }}
          >
            <h1 className="text-4xl md:text-6xl font-bold text-white my-6">
              {t('home.hero')}
            </h1>
            <p className="text-xl text-white/90 mb-8">
              {t('home.heroDescription')}
            </p>
            <div className="flex flex-wrap gap-4">
              <Link className="text-decoration-none" to="/products">
                <Button type="primary"
                  size="large" className="h-12 px-8">
                  {t('home.heroButton1')}
                </Button>
              </Link>
            </div>
          </motion.div>
        </Col>

        <Col xs={24} md={12} className="z-10 mt-12 md:mt-0">
          <div className="grid grid-cols-2 gap-6 py-4 xl:p-8 lg:p-8 md:p-8">
            <motion.div
              className="bg-white/10 backdrop-blur-lg rounded-2xl p-6 shadow-lg"
              whileHover={{
                y: -5,
              }}
              animate={{
                y: [0, -10, 0],
              }}
              transition={{
                duration: 3,
                repeat: Infinity,
              }}
            >
              <Lock className="w-12 h-12 text-white mb-4" />
              <h3 className="text-xl text-white font-semibold">{t('home.heroCard1')}</h3>
              <p className="text-white/80">{t('home.heroCard1Description')} </p>
            </motion.div>

            <motion.div
              className="bg-white/10 backdrop-blur-lg rounded-2xl p-6 shadow-lg"
              whileHover={{
                y: -5,
              }}
              animate={{
                y: [0, -10, 0],
              }}
              transition={{
                duration: 3,
                repeat: Infinity,
                delay: 0.2,
              }}
            >
              <Zap className="w-12 h-12 text-white mb-4" />
              <h3 className="text-xl text-white font-semibold">{t('home.heroCard2')}</h3>
              <p className="text-white/80">{t('home.heroCard2Description')}</p>
            </motion.div>

            <motion.div
              className="bg-white/10 backdrop-blur-lg rounded-2xl p-6 shadow-lg"
              whileHover={{
                y: -5,
              }}
              animate={{
                y: [0, -10, 0],
              }}
              transition={{
                duration: 3,
                repeat: Infinity,
                delay: 0.4,
              }}
            >
              <Shield className="w-12 h-12 text-white mb-4" />
              <h3 className="text-xl text-white font-semibold">{t('home.heroCard3')}</h3>
              <p className="text-white/80">{t('home.heroCard3Description')}</p>
            </motion.div>

            <motion.div
              className="bg-white/10 backdrop-blur-lg rounded-2xl p-6 shadow-lg"
              whileHover={{
                y: -5,
              }}
              animate={{
                y: [0, -10, 0],
              }}
              transition={{
                duration: 3,
                repeat: Infinity,
                delay: 0.6,
              }}
            >
              <img
                src="https://cdn-icons-png.flaticon.com/512/2945/2945650.png"
                alt="Smart Hub"
                className="w-12 h-12 mb-4 invert"
              />
              <h3 className="text-xl text-white font-semibold">{t('home.heroCard4')}</h3>
              <p className="text-white/80">{t('home.heroCard4Description')}</p>
            </motion.div>
          </div>
        </Col>
      </Row>
    </main>
  );
};

export default Hero;

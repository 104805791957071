import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { countCart } from "../../network/cart";
import { useQuery } from "@tanstack/react-query";
import { Badge, Button, Dropdown, Image } from 'antd';
import { withTranslation } from "react-i18next";
import { CloseOutlined, HeartOutlined } from '@ant-design/icons';
import langIconEn from "../../assets/images/en.png"
import langIconAr from "../../assets/images/ar.png"
import { MenuIcon } from "lucide-react";
import { ShoppingCart, User } from "lucide-react";

const NavMenuDesktop = ({ t, i18n, user }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isRTL = i18n.language === 'ar';
  const styles = {
    link: "text-base font-medium text-black",
    iconButton:
      `flex items-center justify-center text-white w-9 h-9 xl:hidden ${isRTL ? 'mr-auto' : 'ml-auto'}`,
    navLink:
      "py-2 text-base font-medium text-black transition-all duration-200 focus:text-blue-600",
  };

  useEffect(() => window.scrollTo(0, 0));

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const { data: cartCount } = useQuery({
    queryKey: ['cartCount', user?.id],
    queryFn: countCart,
    enabled: !!user,
  });

  const handleLanguageChange = () => {
    if (i18n.language === "ar") {
      i18n.changeLanguage('en');
    } else {
      i18n.changeLanguage('ar');
    }
  };

  const logout = () => {
    localStorage.clear();
    window.location.reload();
  };

  const items = [
    {
      key: '1',
      label: (
        <Link className="text-[13px] font-roboto tracking-[-0.1px] uppercase no-underline text-gray-500 font-bold" to="/profile">
          {t('profile')}
        </Link>
      ),
    },
    {
      key: '2',
      label: (
        <Link className="text-[13px] font-roboto tracking-[-0.1px] uppercase no-underline text-gray-500 font-bold" to="/orders">
          {t('orders')}
        </Link>
      ),
    },
    {
      key: '3',
      label: (
        <Link className="text-[13px] font-roboto tracking-[-0.1px] uppercase no-underline text-gray-500 font-bold" to="/" onClick={logout}>
          {t('logout')}
        </Link>
      ),
    }
  ];

  const renderNavItems = () => (
    <div >
      {localStorage.getItem('token') ? (
        <div>
          <Link to="/favorites" className="text-black" style={{ textDecoration: 'none' }}>
            <HeartOutlined className="responsive-icon hover:text-gray-700 cursor-pointer" style={{ fontSize: '22px' }} />
          </Link>

          <Link to="/cart" className="text-black mx-5" style={{ textDecoration: 'none' }}>
            <Badge count={cartCount} size="small">
              <ShoppingCart className="hover:text-gray-700 cursor-pointer responsive-icon w-5 h-5" />
            </Badge>
          </Link>

          <Dropdown menu={{ items }}>
            <button className="rounded-full bg-gray-100 p-2">
              <User className="w-5 h-5" />
            </button>
          </Dropdown>
        </div>
      ) : (
        <div>
          <Link className="text-[13px] font-roboto tracking-[-0.1px] uppercase no-underline text-gray-500 font-bold" to="/login" style={{ textDecoration: 'none' }}>
            <Button
              type="default"
              className="px-6 hover:bg-gray-50 hover:border-gray-400"
            >
              {t('auth.login')}
            </Button>
          </Link>
          <Link className="text-[13px] font-roboto tracking-[-0.1px] mx-2 uppercase no-underline text-gray-500 font-bold" to="/register" style={{ textDecoration: 'none' }}>
            <Button
              type="primary"
              className="px-6 bg-[rgb(82,113,255)] hover:bg-[rgb(62,93,235)] border-none"
            >
              {t('auth.signUp')}
            </Button>
          </Link>
        </div>
      )}
    </div>
  );

  const renderMenuItemsOpen = () => (
    <div >
      {localStorage.getItem('token') ? (
        <div>
          <div className="flex flex-col space-y-2">
            <Link className={styles.navLink} to="/cart">
              {t('footer.myCart')}
            </Link>
            <Link className={styles.navLink} to="/favorites">
              {t('footer.wishlist')}
            </Link>
            <Link className={styles.navLink} to="/orders">
              {t('orders')}
            </Link>
          </div>
          <hr className="my-4 border-gray-200" />
          <div className="flex flex-col space-y-2">
            <Link className={styles.navLink} to="/profile">
              {t('profile')}
            </Link>
            <Link className={styles.navLink} to="/" onClick={logout}>
              {t('logout')}
            </Link>
          </div>
        </div>
      ) : (
        <div className="flex flex-col space-y-2">
          <Link className={styles.navLink} to="/login">
            {t('auth.login')}
          </Link>
          <Link className={styles.navLink} to="/register">
            {t('auth.signUp')}
          </Link>
        </div>
      )}
    </div>
  );

  const renderButtonLanguage = () => (
    <Button
      className="border-none xl:mx-5"
      onClick={handleLanguageChange}
      icon={
        i18n.language === 'ar' ? (
          <img
            src={langIconAr}
            alt=""
            className="border-none mt-3 xl:w-[60px] xl:h-[50px] md:w-[60px] md:h-[50px] lg:w-[60px] lg:h-[50px] w-[35px] h-[35px]"
          />
        ) : (
          <img
            src={langIconEn}
            alt=""
            className="border-none mt-3 xl:w-[60px] xl:h-[50px] md:w-[60px] md:h-[50px] lg:w-[60px] lg:h-[50px] w-[35px] h-[35px]"
          />
        )
      }
    />
  );

  return (
    <header className="bg-white">
      {/* lg+ */}
      <div className="px-4 mx-auto sm:px-6 lg:px-8">
        <nav className="relative flex items-center justify-between h-16 lg:h-20">
          <div className="hidden xl:flex xl:items-center xl:space-x-10">
            {renderButtonLanguage()}
          </div>
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <Link to="/" className="flex" title="Aura UI">
                <Image
                  src={require('../../assets/images/logo.png')}
                  alt="logo"
                  preview={false}
                  className="responsive-logo"
                />
              </Link>
            </div>
          </div>
          <div type="button" className={styles.iconButton}>
            {renderButtonLanguage()}
          </div>
          <button
            type="button"
            className="inline-flex p-2 ml-5 text-black transition-all duration-200 rounded-md xl:hidden focus:bg-gray-100 hover:bg-gray-100"
            onClick={toggleMenu}
          >
            {isMenuOpen ? (
              <CloseOutlined className="h-6" />
            ) : (
              <MenuIcon className="w-6 h-6" />
            )}
          </button>
          <div className="hidden xl:flex xl:items-center xl:space-x-10">
            {renderNavItems()}
          </div>
        </nav>
      </div>

      {/* xs to lg */}
      {isMenuOpen && (
        <nav className="py-4 bg-white xl:hidden">
          <div className="px-4 mx-auto sm:px-6 lg:px-8">
            {renderMenuItemsOpen()}
          </div>
        </nav>
      )}
    </header>
  );
};

export default withTranslation()(NavMenuDesktop);
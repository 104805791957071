import React from 'react';
import { Card, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { nextStep, prevStep } from '../../services/store/slices/cartSlice';
import cogoToast from 'cogo-toast';
import { addOrder } from '../../network/order';
import { useMutation } from '@tanstack/react-query';
import queryClient from '../../services/react-query/queryClient';
import { queryKeys } from '../../services/react-query/queryKeys';

const Payment = ({ t }) => {
  const cart = useSelector((state) => state.cart);
  const total = cart.data;
  const dispatch = useDispatch();

  const { mutate: onBuyNow, isPending: isBuyPending } = useMutation({
    mutationFn: async (formData) => await addOrder(formData),
    onSuccess: async (data) => {
      if (data.status === "true") {
        if (data.data.payment_url) {
          window.location.href = data.data.payment_url;
        } else {
          cogoToast.success(t("messages.orderPlacedSuccessfully"), {
            position: "top-right",
          });
          queryClient.invalidateQueries([queryKeys.listOrders]);
        }
      } else {
        cogoToast.error(t("messages.errorOccurred"), {
          position: "top-right",
        });
      }
    },
    onError: () => {
      cogoToast.error(t("messages.errorOccurred"), {
        position: "top-right",
      });
    },
  });

  const onFinish = async () => {
    const orderData = {
      delivery_address: cart.delivery_address,
      payment_method: "cash",
      promo_code_id: cart.promoCodeId ? cart.promoCodeId : null,
    };

    onBuyNow(orderData);
  };

  const handleNext = () => {
    dispatch(nextStep());
    onFinish();
  };

  return (
    <Card className="max-w-2xl mx-auto shadow-md">
      <div className="space-y-6">
        <div className="bg-gray-50 p-4 rounded-lg">
          <h3 className="font-medium text-lg mb-2">{t("messages.cashPayment")}</h3>
          <p className="text-gray-600">
            {t("messages.cashPaymentDescription")}
          </p>
        </div>
        <div className="border-t pt-4">
          <h4 className="font-medium mb-2">
            {t("messages.orderTotal")}: {total.toFixed(2)} {t("currency")}
          </h4>
          <p className="text-gray-600 text-sm">
            {t("messages.cashDeliveryNote")}
          </p>
        </div>
        <div className="flex justify-between mt-6">
          <Button onClick={() => dispatch(prevStep())}>
            {t('messages.previous')}
          </Button>
          <Button type="primary" onClick={handleNext} loading={isBuyPending}>
            {t('auth.submit')}
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default Payment;

import React, { useState } from "react";
import { queryKeys } from "../../services/react-query/queryKeys";
import { listCart, removeItem, itemPlus, itemMinus } from "../../network/cart";
import cogoToast from "cogo-toast";
import { nextStep, setCart, setPromoCodeId } from '../../services/store/slices/cartSlice';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
    Card,
    Input,
    InputNumber,
    Button,
    Divider,
    Collapse,
    Col,
    Row,
    Spin,
    Empty,
    Typography,
} from "antd";
import {
    Trash2,
    MinusCircle,
    PlusCircle,
} from "lucide-react";
import { validPromo } from "../../network/promo";
import { useDispatch } from "react-redux";

const CartReview = ({ t, i18n }) => {
    const [promoCode, setPromoCode] = useState("");
    const [promoCodeData, setPromoCodeData] = useState(null);
    const [promoCodeDiscount, setPromoCodeDiscount] = useState(0);
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const [loadingItemId, setLoadingItemId] = useState(null);

    const { data: cartData, isLoading } = useQuery({
        queryKey: [queryKeys.listCart, i18n],
        queryFn: listCart,
        enabled: !!i18n,
    });

    const { mutate: itemPlusHandler, isPending: isIncrementPending } = useMutation({
        mutationFn: (params) => itemPlus(params),
        onMutate: (itemId) => {
            setLoadingItemId(itemId);
        },
        onSuccess: (data) => {
            if (data.status === "true") {
                cogoToast.success(t('messages.itemQuantityIncreased'), {
                    position: "top-right",
                });
                queryClient.invalidateQueries([queryKeys.listCart]);
            } else {
                cogoToast.error(t("messages.errorOccurred"), {
                    position: "top-right",
                });
            }
            setLoadingItemId(null);
        },
        onError: () => {
            cogoToast.error(t("messages.errorOccurred"), {
                position: "top-right",
            });
            setLoadingItemId(null);
        },
    });

    const { mutate: itemMinusHandler, isPending: isDecrementPending } = useMutation({
        mutationFn: (params) => itemMinus(params),
        onMutate: (itemId) => {
            setLoadingItemId(itemId);
        },
        onSuccess: (data) => {
            if (data.status === "true") {
                cogoToast.success(t('messages.itemQuantityDecreased'), {
                    position: "top-right",
                });
                queryClient.invalidateQueries([queryKeys.listCart]);
            } else {
                cogoToast.error(t("messages.errorOccurred"), {
                    position: "top-right",
                });
            }
            setLoadingItemId(null);
        },
        onError: () => {
            cogoToast.error(t("messages.errorOccurred"), {
                position: "top-right",
            });
            setLoadingItemId(null);
        },
    });

    const { mutate: removeItemMutation, isPending: isRemovePending } = useMutation({
        mutationFn: (params) => removeItem(params),
        onSuccess: (data) => {
            if (data.status === "true") {
                cogoToast.success(t('messages.cartItemRemoved'), {
                    position: "top-right",
                });
                queryClient.invalidateQueries([queryKeys.listCart]);
            } else {
                cogoToast.error(t("messages.errorOccurred"), {
                    position: "top-right",
                });
            }
        },
        onError: () => {
            cogoToast.error(t("messages.errorOccurred"), {
                position: "top-right",
            });
        },
    });

    const { mutate: applyPromoCode, isPending: isApplying } = useMutation({
        mutationFn: async ({ code }) => validPromo(code),
        onSuccess: (data) => {
            if (data.status === "true") {
                cogoToast.success(t("messages.promoCodeApplied"), {
                    position: "top-right",
                });
                setPromoCode(data.data.code);
                setPromoCodeData(data);
                setPromoCodeDiscount(parseFloat(data.data.value));
                queryClient.invalidateQueries([queryKeys.listCart]);
            } else {
                cogoToast.error(t("messages.inValidpromoCode"), {
                    position: "top-right",
                });
            }
        },
        onError: (error) => {
            cogoToast.warn(t("messages.inValidpromoCode"), {
                position: "top-right",
            });
        },
    });

    const handleApplyPromo = () => {
        if (promoCode.trim()) {
            applyPromoCode({ code: promoCode });
        } else {
            cogoToast.warn(t("messages.enterPromoCode"), {
                position: "top-right",
            });
        }
    };

    const handleNext = () => {
        dispatch(nextStep());
        dispatch(setCart(total));
        dispatch(setPromoCodeId(promoCodeData?.data?.id));
    };

    const calculateSubtotal = (cartItems) => {
        return cartItems.reduce((total, item) => {
            const price = item.items?.new_price ?? item.items?.price;
            return total + price * item.quantity;
        }, 0);
    };

    const calculateTotal = (subtotal, shipping, discount = 0) => {
        return subtotal + shipping - discount;
    };

    const subtotal = calculateSubtotal(cartData?.data || []);
    const shipping = 0;
    const total = calculateTotal(subtotal, shipping, promoCodeDiscount);

    if (isLoading) {
        return (
            <div className="grid content-center bg-gray-100 h-[280px]">
                <div className="text-center">
                    <Spin size="large" />
                </div>
            </div>
        );
    }

    if (cartData?.data?.length === 0) {
        return (
            <div className="grid content-center bg-gray-100 h-[380px]">
                <Empty description={
                    <Typography.Text>
                        {t('cartEmpty')} <a href="/">{t('addNow')}</a>
                    </Typography.Text>
                }
                />
            </div>
        );
    }
    return (
        <div className="flex flex-col lg:flex-row gap-8">
            <div className="flex-1">
                <Card className="mb-4" title={
                    <div className="flex items-center justify-between">
                        <span>{t('myCart')}</span>
                        <span className="text-sm text-gray-500">{cartData?.data.length}  {t('items')}</span>
                    </div>}>
                    {cartData?.data?.map((CartList, index) => (
                        <Row
                            key={index}
                            className="items-center gap-4 p-4 border-b hover:bg-gray-50 transition-colors"
                        >
                            <Col xl={5}>
                                <img
                                    src={CartList?.items?.image ?? CartList?.items?.images[0]}
                                    alt={CartList?.items?.name}
                                    className="w-[120px] h-35 object-cover rounded-md"
                                />
                            </Col>
                            <Col xl={16}>
                                <div className="flex justify-between items-start mb-2">
                                    <div>
                                        <h3 className="text-lg font-semibold">{CartList?.items?.name}</h3>
                                    </div>
                                    <Button
                                        danger
                                        icon={<Trash2 className="w-4 h-4" />}
                                        type="text"
                                        onClick={() => removeItemMutation(CartList.id)}
                                        loading={isRemovePending}
                                    >
                                        <div className="hidden sm:block">{t('messages.remove')}</div>
                                    </Button>
                                </div>
                                <div className="flex items-center gap-2 mt-1">
                                    {CartList.items?.new_price === null ?
                                        <div className="flex items-baseline gap-4">
                                            <span className="font-medium font-bold text-blue-600">
                                                {CartList.items?.price}{t('currency')}
                                            </span>
                                        </div>
                                        :
                                        <div className="flex items-baseline gap-4">
                                            <span className="font-medium font-bold text-blue-600">{CartList.items?.new_price}{t('currency')}</span>
                                            <span className="text-gray-500 line-through">{CartList.items?.price}{t('currency')}</span>
                                            <span className="bg-red-100 text-red-800 text-sm font-medium px-2.5 py-0.5 rounded">
                                                {Math.round(((CartList.items?.price - CartList.items?.new_price) / CartList.items?.price) * 100)}% {t('discount')}
                                            </span>
                                        </div>
                                    }
                                </div>
                                <Row className="items-center mt-4" gutter={12} justify={"space-between"}>
                                    <Col className="items-center border rounded-md p-1">
                                        <Button
                                            type="text"
                                            icon={<MinusCircle className="w-4 h-4" />}
                                            disabled={CartList.quantity === "1"}
                                            onClick={() => itemMinusHandler(CartList.id)}
                                            loading={isDecrementPending && loadingItemId === CartList.id}
                                        />
                                        <InputNumber
                                            min={1}
                                            value={CartList.quantity}
                                            className="w-16 border-none"
                                            controls={false}
                                        />
                                        <Button
                                            type="text"
                                            icon={<PlusCircle className="w-4 h-4" />}
                                            onClick={() => itemPlusHandler(CartList.id)}
                                            loading={isIncrementPending && loadingItemId === CartList.id}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    ))}
                </Card>
            </div>

            <div className="lg:w-1/3">
                <Card title={t("messages.orderSummary")} className="sticky top-4">
                    <div className="space-y-4">
                        <Collapse
                            defaultActiveKey={["1"]}
                            items={[
                                {
                                    key: "1",
                                    label: t("messages.applyPromoCode"),
                                    children: (
                                        <div className="flex gap-2">
                                            <Input
                                                placeholder={t("messages.enterPromoPlaceholder")}
                                                value={promoCode}
                                                onChange={(e) => setPromoCode(e.target.value)}
                                                className="hover:border-[#1677ff]"
                                                aria-label="Promo Code Input"
                                            />
                                            <Button
                                                type="primary"
                                                onClick={handleApplyPromo}
                                                loading={isApplying}
                                                disabled={!promoCode.trim()}
                                                className="text-white"
                                                aria-label="Apply Promo Code"
                                            >
                                                {t("messages.apply")}
                                            </Button>
                                        </div>
                                    ),
                                },
                            ]}
                        />
                        <Divider />

                        <div className="space-y-2">
                            <div className="flex justify-between">
                                <span>{t("messages.subtotal")}</span>
                                <span>{subtotal.toFixed(2)} {t("currency")}</span>
                            </div>
                            <div className="flex justify-between">
                                <span>{t("messages.shipping")}</span>
                                <span>{shipping.toFixed(2)} {t("currency")}</span>
                            </div>
                            {promoCodeDiscount > 0 && (
                                <div className="flex justify-between text-green-500">
                                    <span>{t("messages.promoDiscount")}</span>
                                    <span>-{promoCodeDiscount.toFixed(2)} {t("currency")}</span>
                                </div>
                            )}
                            <Divider />
                            <div className="flex justify-between text-lg font-medium">
                                <span>{t("messages.total")}</span>
                                <span className="text-[#1677ff]">{total.toFixed(2)} {t("currency")}</span>
                            </div>
                        </div>

                        <Button type="primary" onClick={handleNext} className="w-full mt-5">
                            {t('messages.continue')}
                        </Button>
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default CartReview;

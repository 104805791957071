import React from 'react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './App';
import './index.css';
import './assets/css/custom.css';
import './assets/css/placeholder-loading.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import axios from 'axios';
import './i18n';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from "react-redux";
import persistStore from "redux-persist/es/persistStore";
import store from "./services/store/configureStore";

axios.interceptors.request.use(function (config) {
  config.headers.Authorization = 'Bearer ' + localStorage.getItem('token');
  return config;
});

const persistor = persistStore(store);
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();

import React from "react";
import { Card } from "antd";
import { AppstoreOutlined, LockOutlined, SafetyOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { t } from "i18next";
const Benefits = () => {

  return (
    <section className="py-20 px-4">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-bold text-center mb-12">
          {t('home.whyChooseUs')}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {[
            {
              icon: <LockOutlined className="text-blue-600" />,
              title: t('home.secureReliable'),
            },
            {
              icon: <ThunderboltOutlined className="text-blue-600" />,
              title: t('home.fastInstallation'),
            },
            {
              icon: <SafetyOutlined className="text-blue-600" />,
              title: t('home.support'),
            },
            {
              icon: <AppstoreOutlined className="text-blue-600" />,
              title: t('home.easyIntegration'),
            },
          ].map((benefit, i) => (
            <Card
              key={i}
              className="text-center hover:shadow-lg transition-all hover:-translate-y-1"
            >
              <div className="text-4xl mb-4">{benefit.icon}</div>
              <h3 className="text-xl font-semibold">{benefit.title}</h3>
            </Card>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Benefits;
